import styled from 'styled-components';

export const FlexDiv = styled.div`
  display: flex;
  ${(props) => (props.direction ? `flex-direction: ${props.direction};` : '')}
  ${(props) => (props.justify ? `justify-content: ${props.justify};` : '')};
  ${(props) => (props.items ? `align-items: ${props.items};` : '')};
  ${(props) => (props.cGap ? `column-gap: ${props.cGap};` : '')};
  ${(props) => (props.rGap ? `row-gap: ${props.rGap};` : '')};
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')};
  ${(props) => (props.wrap ? `flex-wrap: ${props.wrap};` : '')};
`;

export default FlexDiv;
