import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setQualityFilter } from 'store/actions';
import styled from 'styled-components';

function QualityOptionsFilter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { feedbackOptions, filter } = useSelector((state) => state.quality);

  const handleSelect = useCallback(
    (event) => {
      const option = event.currentTarget.dataset.option;

      dispatch(setQualityFilter(option));
    },
    [dispatch],
  );

  const handleClear = useCallback(() => {
    dispatch(setQualityFilter(null));
  }, [dispatch]);

  if (feedbackOptions.length === 0) {
    return null;
  }

  return (
    <QuestionAssessment>
      <span>{t('qualityPage.btnsTitle')}</span>
      <div
        style={{
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '4px',
        }}
      >
        <button
          key={'all'}
          type="button"
          onClick={handleClear}
          className={`assessment-btn ${filter === null ? 'active' : ''}`}
        >
          Все
        </button>
        {feedbackOptions.map((option, index) => (
          <button
            key={index}
            type="button"
            data-option={option}
            onClick={handleSelect}
            className={`assessment-btn ${option === filter ? 'active' : ''}`}
          >
            {option}
          </button>
        ))}
      </div>
    </QuestionAssessment>
  );
}

const QuestionAssessment = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #7f7f7f;
  }
  .assessment-btn {
    padding: 8px 12px;
    cursor: pointer;
    font-weight: 400;
    border: 0;
    background: inherit;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    line-height: 19px;
    color: #000000;
    &.active {
      background: #006ae4;
      color: #ffffff;
    }
  }
`;

export default QualityOptionsFilter;
