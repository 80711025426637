import { apiGet, apiPatch, apiPost, apiPut } from 'api';
import { HOST } from 'config';
import { setCompanyShowQuality } from './settingsActions';

// settings
export const QUALITY_SET_FEEDBACK_MESSAGE_PREFIX =
  'QUALITY_SET_FEEDBACK_MESSAGE_PREFIX';
export const QUALITY_SET_FEEDBACK_OPTIONS = 'QUALITY_SET_FEEDBACK_OPTIONS';

export const setQualityFeedbackMessagePrefix = (message) => ({
  type: QUALITY_SET_FEEDBACK_MESSAGE_PREFIX,
  payload: message,
});

export const setQualityFeedbackOptions = (options) => ({
  type: QUALITY_SET_FEEDBACK_OPTIONS,
  payload: options,
});

// stat
export const QUALITY_SET_MESSAGES = 'QUALITY_SET_MESSAGES';
export const QUALITY_SET_STAT = 'QUALITY_SET_STAT';
export const QUALITY_SET_FILTER = 'QUALITY_SET_FILTER';

export const setQualityMessages = (messages) => ({
  type: QUALITY_SET_MESSAGES,
  payload: messages,
});

export const setQualityStat = (stat) => ({
  type: QUALITY_SET_STAT,
  payload: stat,
});

export const setQualityFilter = (option) => ({
  type: QUALITY_SET_FILTER,
  payload: option,
});

export const createQualitySettings = (uuid) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiPost({
      dispatch,
      url: `${HOST}/company/${
        getState().session.selectedCompany
      }/quality-settings`,
      tokens: getState().session.tokenData,
      body: {
        uuid,
        text: getState().quality.feedbackMessagePrefix,
        options: getState().quality.feedbackOptions,
      },
      callback: (response) => {
        try {
          if (response?.success) {
            resolve(response.data);
          } else {
            reject(new Error('Ошибка при создании настроек'));
          }
        } catch (error) {
          reject(error);
        }
      },
      onError: (error) => {
        reject(error);
      },
    });
  });
};

export const updateQualitySettings = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiPut({
      dispatch,
      url: `${HOST}/company/${
        getState().session.selectedCompany
      }/quality-settings`,
      tokens: getState().session.tokenData,
      body: {
        text: getState().quality.feedbackMessagePrefix,
        options: getState().quality.feedbackOptions,
      },
      callback: (response) => {
        try {
          if (response?.success) {
            resolve(response.data);
          } else {
            reject(new Error('Ошибка при обновлении настроек'));
          }
        } catch (error) {
          reject(error);
        }
      },
      onError: (error) => {
        reject(error);
      },
    });
  });
};

export const getQualitySettings = () => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiGet({
      dispatch,
      url: `${HOST}/company/${
        getState().session.selectedCompany
      }/quality-settings`,
      tokens: getState().session.tokenData,
      callback: (response) => {
        try {
          if (response?.success) {
            resolve(response.data);
          } else {
            reject();
          }
        } catch (error) {
          reject(error);
        }
      },
      onError: (error) => {
        reject(error);
      },
    });
  });
};

export const updateShowQualitySettings =
  (showQualitySettings) => (dispatch, getState) =>
    apiPatch({
      dispatch,
      url: `${HOST}/company/${
        getState().session.selectedCompany
      }/showQualitySettings`,
      tokens: getState().session.tokenData,
      body: { showQualitySettings },
      callback: (data) => {
        if (data.success) {
          dispatch(setCompanyShowQuality(showQualitySettings));
        }
      },
    });

// stat

export const getQualityFeedbackStat =
  ({ uuidCompany, from, to, employees = [], uuidConnection }) =>
  (dispatch, getState) =>
    apiPost({
      dispatch,
      url: `${HOST}/chat/history/messages/getQualityFeedbackStat`,
      tokens: getState().session.tokenData,
      body: {
        uuidCompany,
        from,
        to,
        uuidConnection,
        employees,
      },
      callback: (data) => {
        try {
          const { messages, stats } = data;
          dispatch(setQualityMessages(messages));
          dispatch(setQualityStat(stats));
        } catch {}
      },
    });
