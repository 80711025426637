import { useCallback, useState } from 'react';

import { Avatar, ListItem, ListItemText } from '@material-ui/core';
import { Instagram } from '@material-ui/icons';

import { useDispatch, useSelector } from 'react-redux';
import ClearButton from 'components/Filter/components/ClearButton';
import SelectFilter from 'components/Filter/components/SelectFilter';
import { useStyles } from 'components/Filter/styles';
import { setSelectedProviders } from 'store/actions/statisticsActions';

export default function ProviderFilter() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState();
  const providers = useSelector((state) => state.chat.companyProviders);
  const selectedProviders = useSelector(
    (state) => state.statistics.selectedProviders,
  );

  // handlers

  const handleOpenClose = (openOrClose) => {
    setOpen(openOrClose);
  };

  const handleSelect = useCallback(
    (event) => {
      const uuid = event.currentTarget.dataset.uuid;

      dispatch(setSelectedProviders(uuid));
      setOpen(false);
    },
    [dispatch],
  );

  const handleClear = useCallback(
    () => dispatch(setSelectedProviders(null)),
    [dispatch],
  );

  const getProviderTextById = useCallback(
    (selectedUuid) => {
      const provider = providers.find(({ uuid }) => uuid === selectedUuid);
      return provider ? provider.name : null;
    },
    [providers],
  );

  return (
    <div className={classes.filter}>
      <Instagram className={classes.filter_icon} />
      <SelectFilter
        text={getProviderTextById(selectedProviders) || 'Выберите провайдера'}
        handleOpenClose={handleOpenClose}
        open={open}
      >
        {providers.map(({ uuid, name, provider }) => (
          <ListItem key={uuid} data-uuid={uuid} onClick={handleSelect}>
            <Avatar
              alt={provider}
              src={`/img/messengers/${provider}.svg`}
              className={classes.avatarImg}
            />
            <ListItemText primary={name} />
          </ListItem>
        ))}
      </SelectFilter>
      <ClearButton handleClear={handleClear} />
    </div>
  );
}
