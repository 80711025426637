import { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QualityMessage from '../QualityMessage';
import { getDepartmentUserName } from 'views/Stat/utils';

function QualityMessageList() {
  const { t } = useTranslation();
  const { filter, messages } = useSelector((state) => state.quality);
  const selectedCompany = useSelector(
    (state) => state.statistics.selectedCompany,
  );
  const { departments = {} } = useSelector((state) => state.session);

  const providers = useSelector((state) => state.chat.companyProviders);

  const companyDepartments = useMemo(
    () => departments[selectedCompany] || {},
    [selectedCompany, departments],
  );

  const allEmployees = useMemo(() => {
    return Object.values(companyDepartments).flatMap(
      (dept) => dept.users || [],
    );
  }, [companyDepartments]);

  const filteredMessages = useMemo(() => {
    return messages.reduce((totalMessages, msg) => {
      if (!msg) return totalMessages;

      const {
        text,
        ids: { uuidUser, uuidConnection },
      } = msg;

      const findedUser = allEmployees.find((emp) => emp.uuid === uuidUser);
      const findedProvider = providers.find(
        (provider) => provider.uuid === uuidConnection,
      );

      if (
        findedUser &&
        findedProvider &&
        (!filter || text?.toLowerCase().includes(filter.toLowerCase()))
      ) {
        totalMessages.push({
          ...msg,
          userName: getDepartmentUserName(findedUser),
          providerName: findedProvider.name,
        });
      }

      return totalMessages;
    }, []);
  }, [allEmployees, messages, providers, filter]);

  return (
    <OperatorsList>
      <div className="list-header">
        <span>{t('qualityPage.listHeader.col1')}</span>
        <span>{t('qualityPage.listHeader.col2')}</span>
        <span>{t('qualityPage.listHeader.col3')}</span>
        <span>{t('qualityPage.listHeader.col4')}</span>
        <span>{t('qualityPage.listHeader.col5')}</span>
      </div>
      {filteredMessages.map((item, index) => (
        <QualityMessage key={index} data={item} />
      ))}
    </OperatorsList>
  );
}

const OperatorsList = styled.div`
  font-weight: 400;
  margin-top: 32px;
  span.link {
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    text-decoration-line: underline;
    color: #006ae4 !important;
  }
  .row,
  .list-header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr 2fr 1fr;
    grid-column-gap: 16px;
  }
  .row {
    padding: 11px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    & span:first-of-type {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    span {
      color: #000000;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .list-header {
    font-size: 12px;
    line-height: 17px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 11px 0;
    color: rgba(0, 0, 0, 0.5);
  }

  .svg {
    margin-left: auto;
    user-select: none;
  }
`;

export default QualityMessageList;
