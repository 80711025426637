import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function QualityMessage({ data }) {
  const { t } = useTranslation();

  const {
    ids: { uuidDialog, uuidMessage },
    userName,
    providerName,
    date,
    value,
    text,
  } = data;

  const formattedDateTime = useMemo(
    () => moment(date).locale('ru').format('DD MMMM YYYY, HH:mm:ss'),
    [date],
  );

  const handleNavigate = useCallback(() => {
    window.open(`/chat/${uuidDialog}#chatMessage-${uuidMessage}`, '_blank');
  }, [uuidDialog, uuidMessage]);

  return (
    <div className="row">
      <span>{providerName}</span>
      <span>{text}</span>
      <span>{userName}</span>
      <span>{formattedDateTime}</span>
      <span>{value}</span>
      <span onClick={handleNavigate} className="link">
        {t('qualityPage.openDialog')}
      </span>
    </div>
  );
}

QualityMessage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default QualityMessage;
