import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Page } from 'components';
import {
  getQualityFeedbackStat,
  getQualitySettings,
  setQualityFeedbackMessagePrefix,
  setQualityFeedbackOptions,
} from 'store/actions';
import { Wrap } from '../../StatPage';
import { FlexDiv, StatNav } from '../../components';
import { QualityFilters } from '../../charts';
import {
  QualityMessageList,
  QualityOptionsFilter,
  QualityOptionsStat,
} from './components';

const QualityPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedCompany, selectedEmployees, selectedProviders } = useSelector(
    (state) => state.statistics,
  );
  const calendarDate = useSelector((state) => state.statCalendar.calendarDate);

  const dateRange = useMemo(() => {
    try {
      const { from, to } = calendarDate;

      return {
        from: from.getTime(),
        to: to.getTime(),
      };
    } catch {
      return null;
    }
  }, [calendarDate]);

  useEffect(() => {
    dispatch(getQualitySettings()).then((res) => {
      const { options, text } = res;
      dispatch(setQualityFeedbackMessagePrefix(text));
      dispatch(setQualityFeedbackOptions(options));
    });
  }, [dispatch]);

  useEffect(() => {
    if (dateRange) {
      const { from, to } = dateRange;

      dispatch(
        getQualityFeedbackStat({
          uuidCompany: selectedCompany,
          from,
          to,
          employees: selectedEmployees,
          uuidConnection: selectedProviders,
        }),
      );
    }
  }, [
    dateRange,
    dispatch,
    selectedCompany,
    selectedEmployees,
    selectedProviders,
  ]);

  return (
    <Page>
      <StatNav />
      <Wrap>
        <QualityFilters />
        <SectionTitle>{t('qualityPage.title')}</SectionTitle>
        <QualityOptionsStat />
        <FlexDiv justify="space-between" items="center" margin="37px 0 0 0">
          <h6
            style={{
              color: '#000000',
              fontSize: '18px',
              lineHeight: '25px',
            }}
          >
            {t('qualityPage.questions')}
          </h6>
          <QualityOptionsFilter />
        </FlexDiv>
        <QualityMessageList />
      </Wrap>
    </Page>
  );
};

const SectionTitle = styled.h4`
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  margin-top: 32px;
  margin-bottom: 8px;
  line-height: 25px;
`;

export default QualityPage;
