import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FlexDiv } from 'views/Stat/components';
import { assessmentColors } from '../../utils';

function QualityOptionsStat() {
  const { t } = useTranslation();
  const { feedbackOptions, stats } = useSelector((state) => state.quality);

  const testDataAssessments = useMemo(() => {
    if (!feedbackOptions || !stats) return [];

    const total = Object.values(stats).reduce((a, b) => a + b, 0) || 1;

    return feedbackOptions.map((title, index) => ({
      id: index + 1,
      title,
      percent: ((stats[index + 1] || 0) / total) * 100,
      quantity: stats[index + 1] || 0,
      color: assessmentColors[feedbackOptions.length]?.[index] || '#000000',
    }));
  }, [feedbackOptions, stats]);

  if (testDataAssessments.length === 0) {
    return null;
  }

  return (
    <FlexDiv margin="32px 0 0 0" cGap="32px">
      {testDataAssessments.map((data) => (
        <AssessmentDiv key={data.id} color={data.color}>
          <div className="square" />
          <div>
            <div>
              {data.id} - {data.title}
            </div>
            <div>
              <span className="percent">{data.percent}% </span>
              {` - ${t('qualityPage.assessment', { quantity: data.quantity })}`}
            </div>
          </div>
        </AssessmentDiv>
      ))}
    </FlexDiv>
  );
}

const AssessmentDiv = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 10px;
  .square {
    width: 13px;
    height: 13px;
    border-radius: 4px;
    background: ${(props) => (props.color ? props.color : 'black')};
  }
  div {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #7f7f7f;
  }
  .percent {
    color: ${(props) => (props.color ? props.color : '')};
  }
`;

export default QualityOptionsStat;
